export const judges = [
  {
    name: "Jennifer Siegel",
    title: "Special Trial Judge",
    slug: "siegel",
    start: "Fall 2024",
    llm: true,
    commitment: "Two years",
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Writing Sample",
    ],
  },
  {
    name: "Maurice B. Foley",
    slug: "foley",
    start: "Fall 2024",
    llm: true,
    commitment: "One Year",
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Writing Sample – not to exceed 10 pages (double spaced)",
    ],
  },
  {
    name: "Cary Douglas Pugh",
    slug: "pugh",
    start: "Fall 2024",
    llm: false,
    commitment: "Two years",
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Writing Sample",
    ],
  },
  {
    name: "Emin Toro",
    slug: "toro",
    start: "Fall 2024",
    commitment: "Two years",
    llm: false,
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Writing Sample",
    ],
  },
  {
    name: "Joseph W. Nega",
    slug: "nega",
    start: "Fall 2024",
    llm: false,
    commitment: "Two years",
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Writing Sample",
    ],
  },
  {
    name: "Tamara W. Ashford",
    slug: "ashford",
    start: "Summer 2024 and Fall 2024",
    llm: false,
    commitment: "Two years",
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Writing Sample",
    ],
  },
  {
    title: "Special Trial Judge",
    name: "Adam B. Landy",
    slug: "landy",
    start: "Summer 2024 and Fall 2024",
    commitment: "Two years",
    llm: false,
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s)",
      "3 References",
      "Writing Sample - not to exceed 10 pages",
    ],
  },
  {
    name: "Ronald L. Buch",
    slug: "buch",
    start: "Summer 2024 or Fall 2024",
    llm: true,
    commitment: "Two years",
    requiredFiles: [
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Cover Letter",
      "Writing Sample - not to exceed 10 pages",
    ],
  },
  {
    name: "Zachary Fried",
    title: "Special Trial Judge",
    slug: "fried",
    start: "Fall 2024",
    llm: false,
    commitment: "One or Two Years",
    requiredFiles: [
      "Cover Letter",
      "Resume",
      "Transcripts - Law School(s) and Undergraduate",
      "3 References",
      "Writing Sample",
    ],
  },
];
