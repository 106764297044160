import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { judges } from "../lib/judges";
import styles from "./Judge.module.css";
import Input from "./Input";
import FileUpload from "./FileUpload";
import { useInput } from "../hooks/useInput";
import { useFiles } from "../hooks/useFiles";
import Joi from "joi";
import LoadingSpinner from "../components/Spinner";

function Judge() {
  const errorMessageRef = useRef(null);
  const scrollToError = () => errorMessageRef.current.scrollIntoView();

  let { judgeName } = useParams();
  const { name, requiredFiles, title, slug } = judges.find(
    (j) => j.slug === judgeName
  );

  const [submissionStarted, setSubmissionStarted] = useState(false);
  const nameFirst = useInput();
  const nameLast = useInput();
  const emailAddress = useInput();
  const lawSchool = useInput();

  const phoneNumber = useInput();
  const files = useFiles([]);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");

  const schema = Joi.object({
    judge: Joi.string().label("Judge").required(),
    nameFirst: Joi.string().label("First Name").required(),
    nameLast: Joi.string().label("Last Name").required(),
    emailAddress: Joi.string()
      .email({ tlds: { allow: false } })
      .label("Email Address")
      .required(),
    phoneNumber: Joi.string().label("Phone Number").required(),
    lawSchool: Joi.string().label("Law School").required(),
    files: Joi.array()
      .items(Joi.string())
      .min(requiredFiles.length === 0 ? 0 : 1)
      .max(10)
      .label("Upload Package"),
  });

  const uploadFile = async (file) => {
    // need to get signed url
    let response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
      method: "POST",
    });
    if (response.status >= 400 && response < 600) {
      throw new Error("Error trying to upload your files.");
    }
    const data = await response.json();

    // then post the document there
    const headers = new Headers({ "Content-Type": "application/pdf" });
    response = await fetch(data.url, {
      method: "PUT",
      mode: "cors",
      body: file,
      headers,
    });

    if (response.status >= 400 && response < 600) {
      throw new Error("Error trying to upload your files.");
    }

    return data.fileName;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submissionStarted) {
      return;
    }
    setSubmissionStarted(true);

    const submission = {
      nameFirst: nameFirst.value,
      judge: slug,
      nameLast: nameLast.value,
      emailAddress: emailAddress.value,
      lawSchool: lawSchool.value,
      phoneNumber: phoneNumber.value,
      files: files.value.map((f) => f.name),
    };

    try {
      const { error } = await schema.validate(submission);
      if (error) throw error;
    } catch (err) {
      console.log({ err });
      const [error] = err.details;
      setSubmissionStarted(false);
      if (error.context.key === "files") {
        setErrors(
          "Application Package is required. Please upload the required documents."
        );
        scrollToError();
      } else {
        setErrors(error.message);
        scrollToError();
      }
      return;
    }
    setErrors("");

    submission.files = [];

    try {
      // upload files in application package
      for (const f of files.value) {
        // filename will be a uuid, we need to attach it to the file somehow
        const fileName = await uploadFile(f);
        submission.files.push({
          uploadedFilename: fileName,
          originalFilename: f.name,
        });
      }
    } catch (err) {
      setSubmissionStarted(false);
      setErrors("Error attempting to upload your files.");
      scrollToError();
      return;
    }
    try {
      // then complete submission
      const response = await fetch(`${process.env.REACT_APP_API_URL}/handler`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(submission),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status >= 400 && response < 600) {
        setSubmissionStarted(false);
        throw new Error("Error trying to save your application.");
      }

      await response.json();
      setSubmissionStarted(false);
      setSuccess("Your application has been received.");
    } catch (err) {
      submissionStarted(false);
      setErrors("Error trying to save your application.");
      scrollToError();
    }
  };

  return (
    <main>
      <h2 className={styles.title}>
        {title || "Judge"} {name}
      </h2>
      <div
        ref={errorMessageRef}
        className={errors ? styles.error : styles.hidden}
      >
        {errors}
      </div>
      {success && <div className={styles.success}>{success}</div>}
      {submissionStarted && <LoadingSpinner>Saving...</LoadingSpinner>}
      {!success && (
        <form onSubmit={handleSubmit}>
          <p>Please fill out the form below</p>
          <Input {...nameFirst}>First Name</Input>
          <Input {...nameLast}>Last Name</Input>
          <Input {...emailAddress}>Email Address</Input>
          <Input {...phoneNumber}>Phone Number</Input>
          <Input {...lawSchool}>Law School</Input>
          {requiredFiles && requiredFiles.length > 0 && (
            <div>
              <label className={styles.label}>Upload Application Package</label>
              <p>Required documents for Judge:</p>
              <ul>
                {requiredFiles.map((f) => (
                  <li key={f}>{f}</li>
                ))}
              </ul>
              <FileUpload {...files} />
            </div>
          )}
          <input
            type="submit"
            className={styles.submit}
            value="Submit"
            disabled={submissionStarted}
          />
        </form>
      )}
    </main>
  );
}

export default Judge;
