import { judges } from "../lib/judges";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";

function Home() {
  // const goLive = new Date(2023, 7, 28, 8, 0, 0, 0);
  // const now = new Date();
  const isLive = false; // now.getTime() >= goLive.getTime();

  return (
    <main>
      <h1 className={styles.title}>US Tax Court Law Clerk Application</h1>
      <p>
        Welcome! The U.S. Tax Court's annual Law Clerk Interview Days begin
        September 18 and conclude September 25, 2022. For additional law clerk
        employment opportunities, please visit the{" "}
        <a href="https://ustaxcourt.gov/law_clerk_program.html">
          Law Clerk Program Page
        </a>
        .
      </p>

      <p>
        We invite applicants to this exciting opportunity! Please see below for
        individual judge application requirements.
      </p>
      <p>
        <strong>
          We are accepting applications from August 28 through September 5.
        </strong>
      </p>
      {isLive ? (
        <ol className={styles.judgeList}>
          {judges
            .sort((a, b) => (a.slug > b.slug ? 1 : -1))
            .sort((a, b) =>
              (!a.title && !b.title) || (a.title && b.title)
                ? 0
                : a.title
                ? 1
                : -1
            )
            .map((judge) => (
              <JudgeItem key={judge.slug} {...judge} />
            ))}
        </ol>
      ) : (
        <p>Applications are no longer being accepted.</p>
      )}
    </main>
  );
}

function JudgeItem({
  name,
  requiredFiles,
  slug,
  start,
  commitment,
  title,
  llm,
}) {
  let requiresFilesText;
  const navigate = useNavigate();

  if (requiredFiles.length > 0) {
    requiresFilesText = (
      <div>
        <strong>Required Application Materials:</strong>
        <ul className={styles.requirements}>
          {requiredFiles.map((requirement) => (
            <li key={requirement}>{requirement}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <li key={slug} className={styles.judgeItem}>
      <h2 className={styles.judgeName}>{`${title || "Judge"} ${name}`}</h2>
      <p>
        <strong>Start Date: </strong> {start}
        <br />
        <strong>Term: </strong> {commitment}
        <br />
        <strong>LL.M. Required: </strong>
        {llm ? "Yes" : "No"}
      </p>
      {requiresFilesText}
      <button
        className={styles.button}
        onClick={(e) => navigate(`/judge/${slug}`)}
      >
        Submit your application
      </button>
    </li>
  );
}
export default Home;
