import styles from "./Layout.module.css";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Layout({ children }) {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.logo}>
            <Link to="/">
              <img
                src="/images/ustc-seal.png"
                alt="United States Tax Court Seal"
                width="500"
                height="100"
              />
            </Link>
          </div>
        </div>
      </header>
      <main className={styles.main}>
        {<Outlet />}
        <p>
          If you are experiencing difficulties submitting your application,
          please call (202) 521-0700
        </p>
      </main>
      <footer className={styles.footer}>
        <div className={styles.footerContainer}>
          <p>
            <img
              src="/images/ustc-seal-blue.png"
              alt="United States Tax Court Seal"
              width="100"
              height="100"
            />
          </p>
          <hr />
          <p className={styles.footerTaxCourt}>United States Tax Court</p>
          <p>400 Second Street, NW, Washington, DC 20217 | (202) 521-0700</p>
          <p>All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
}
