import { useState } from "react";

export const useFiles = (initialValue = []) => {
  const [value, setValue] = useState(initialValue);

  const addFiles = (newFiles) => {
    setValue([...value, ...newFiles]);
  };

  const removeFile = (location) => {
    if (location === 0) {
      setValue(value.slice(1));
    } else if (location === value.length - 1) {
      setValue(value.slice(0, location));
    } else {
      setValue([...value.slice(0, location), ...value.slice(location + 1)]);
    }
  };

  return {
    value,
    addFiles,
    removeFile,
  };
};
